const BASE_SPACE = 16

const HEADER_HEIGHT = {
  desktop: '80px',
  mobile: '64px',
}

const DEFAULT_CONTAINER = {
  paddingX: [1, null, 4],
}

const BUTTON_COMMON = {
  cursor: 'pointer',
  fontSize: ['16px', null, 'small'],
  fontFamily: 'body',
  fontWeight: 'medium',
  display: 'inline-block',
  paddingY: 0.5,
  paddingX: 3,
  backgroundColor: 'brand',
  color: 'textDark',
  borderRadius: 'medium',
  textDecoration: 'none',
  textAlign: 'center',
  border: '1px solid',
  borderColor: 'brand',
  transition: 'default',
  transitionProperty: 'background-color, color, border-color',
  width: ['100%', 'unset'],
  ':hover, :active': {
    backgroundColor: 'brandInteraction',
    color: 'textDark',
    borderColor: 'brandInteraction',
  },
}

const DEFAULT_FORM_FIELD = {
  borderRadius: 'medium',
  backgroundColor: 'backgroundLight',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  padding: '8px 16px',
  fontSize: ['16px', null, 'small'],
  fontWeight: 'regular',
  fontFamily: 'body',
  outline: 0,
  ':focus': {
    borderColor: 'brandInteraction',
  },
  '::placeholder': {
    color: 'textGrey',
  },
  ':disabled': {
    backgroundColor: 'backgroundLightDisabled',
    color: 'textGrey',
  },
}

const COMMON_TEXT = {
  whiteSpace: 'pre-wrap',
}

const LINK_NAVIGATION_COMMON = {
  fontSize: 'small',
  display: 'block',
  margin: '-8px',
  padding: '8px',
  textDecoration: 'none',
  transition: 'default',
  transitionProperty: 'color',
  whiteSpace: 'nowrap',
}

const BUTTON_NAVIGATION_COMMON = {
  ...BUTTON_COMMON,
  fontSize: 'small',
  paddingY: 0.25,
  paddingX: 1,
  whiteSpace: 'nowrap',
}

export default {
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'regular',
      lineHeight: 'body',
      fontSize: ['14px', null, '16px'],
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      WebkitTapHighlightColor: 'transparent',
      body: {
        minWidth: '320px',
      },
      ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
      },
      a: {
        color: 'text',
        transition: 'default',
        transitionProperty: 'color',
        ':hover': {
          color: 'textInteraction',
        },
      },
      b: {
        fontWeight: 'medium',
      },
    },
  },
  layout: {
    container: DEFAULT_CONTAINER,
    containerSmall: {
      ...DEFAULT_CONTAINER,
      maxWidth: 'containerSmall',
    },
    containerMedium: {
      ...DEFAULT_CONTAINER,
      maxWidth: 'containerMedium',
    },
    containerFullWidth: {
      ...DEFAULT_CONTAINER,
      maxWidth: 'none',
    },
    contentSmall: {
      maxWidth: 'contentSmall',
    },
  },
  space: {
    0.25: `${BASE_SPACE / 4}px`,
    0.5: `${BASE_SPACE / 2}px`,
    base: `${BASE_SPACE}px`,
    1: `${BASE_SPACE}px`,
    2: `${BASE_SPACE * 2}px`,
    3: `${BASE_SPACE * 3}px`,
    4: `${BASE_SPACE * 4}px`,
    5: `${BASE_SPACE * 5}px`,
    6: `${BASE_SPACE * 6}px`,
    headerDesktop: HEADER_HEIGHT.desktop,
    headerMobile: HEADER_HEIGHT.mobile,
  },
  sizes: {
    container: '1408px',
    containerMedium: '940px',
    containerSmall: '680px',
    contentSmall: '592px',
    headerDesktop: HEADER_HEIGHT.desktop,
    headerMobile: HEADER_HEIGHT.mobile,
    fullHeightContentDesktop: `calc(100vh - (${HEADER_HEIGHT.desktop} * 2))`,
    fullHeightContentMobile: `calc(100vh - (${HEADER_HEIGHT.mobile} * 2))`,
  },
  fonts: {
    body: 'poppins, sans-serif',
    heading: 'poppins, sans-serif',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
  },
  fontSizes: {
    fine: '0.65rem',
    small: '0.9rem',
    body: '1rem',
    prospectBody: '1.25rem',
  },
  lineHeights: {
    body: 1.8,
    heading: 1.4,
    prospectBody: 1.5,
  },
  colors: {
    brand: '#3ee5dd',
    brandInteraction: '#25CCC4',
    text: '#f1f1f5',
    border: '#f1f1f5',
    textInteraction: '#d8d8dc',
    textDark: '#12182b',
    textDarkInteraction: '#000012',
    background: '#12182b',
    backgroundInteraction: '#1F2538',
    backgroundLight: '#2c3245',
    backgroundLightDisabled: '#1d2335',
    textGrey: '#787e91',
    textDarkGrey: '#696974',
    danger: '#d94e45',
  },
  text: {
    body: {
      ...COMMON_TEXT,
      fontSize: 'body',
      lineHeight: 'body',
      color: 'text',
      whiteSpace: 'pre-wrap',
    },
    bodyUppercase: {
      ...COMMON_TEXT,
      fontSize: 'body',
      textTransform: 'uppercase',
    },
    prospectBody: {
      ...COMMON_TEXT,
      fontSize: 'prospectBody',
      fontWeight: 'regular',
      lineHeight: 'prospectBody',
      whiteSpace: 'pre-wrap',
    },
    headingHero: {
      ...COMMON_TEXT,
      fontSize: ['3.2rem', null, '4rem'],
    },
    heading1: {
      ...COMMON_TEXT,
      fontSize: '2.65rem',
      fontWeight: 'semibold',
    },
    heading2: {
      ...COMMON_TEXT,
      fontSize: '2rem',
      fontWeight: 'semibold',
    },
    heading3: {
      ...COMMON_TEXT,
      fontSize: '1.8rem',
      fontWeight: 'semibold',
    },
    heading4: {
      ...COMMON_TEXT,
      fontSize: '1.4rem',
      fontWeight: 'semibold',
    },
    heading5: {
      ...COMMON_TEXT,
      fontSize: '1.2rem',
      fontWeight: 'semibold',
    },
    heading6: {
      ...COMMON_TEXT,
      fontSize: '1rem',
      fontWeight: 'semibold',
    },
    formErrorMessage: {
      ...COMMON_TEXT,
      position: [null, null, 'absolute'],
      marginTop: 0.5,
      color: 'danger',
    },
    finePrint: {
      ...COMMON_TEXT,
      color: 'text',
      fontSize: 'fine',
    },
    small: {
      ...COMMON_TEXT,
      color: 'text',
      fontSize: 'small',
    },
  },
  links: {
    buttonLink: {
      display: ['block', 'unset'],
      width: ['100%', 'unset'],
      textAlign: ['center', 'unset'],
      border: '1px solid',
      borderColor: 'transparent',
      textDecoration: 'none',
      fontSize: 'body',
      fontWeight: 'regular',
      color: 'inherit',
      textUnderlineOffset: '5px',
      outline: 'none',
      ':hover, :focus': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    navigation: {
      ...LINK_NAVIGATION_COMMON,
      color: 'text',
      ':hover': {
        color: 'textInteraction',
      },
    },
    navigationDark: {
      ...LINK_NAVIGATION_COMMON,
      color: 'textDark',
      ':hover': {
        color: 'textDarkInteraction',
      },
    },
    fadeOnHover: {
      transition: 'default',
      transitionProperty: 'opacity',
      ':hover': {
        opacity: 0.8,
      },
    },
    padded: {
      display: 'block',
      margin: '-8px',
      padding: '8px',
    },
  },
  radii: {
    medium: 12,
    large: 16,
  },
  buttons: {
    primary: BUTTON_COMMON,
    dark: {
      ...BUTTON_COMMON,
      backgroundColor: 'background',
      borderColor: 'background',
      color: 'text',
      ':hover': {
        color: 'text',
        borderColor: 'backgroundInteraction',
        backgroundColor: 'backgroundInteraction',
      },
    },
    navigation: {
      ...BUTTON_NAVIGATION_COMMON,
    },
    navigationDark: {
      ...BUTTON_NAVIGATION_COMMON,
      backgroundColor: 'background',
      borderColor: 'background',
      color: 'text',
      ':hover': {
        color: 'text',
        borderColor: 'backgroundInteraction',
        backgroundColor: 'backgroundInteraction',
      },
    },
    outline: {
      ...BUTTON_COMMON,
      backgroundColor: 'transparent',
      color: 'brand',
      ':hover': {
        backgroundColor: 'transparent',
        borderColor: 'brandInteraction',
        color: 'brandInteraction',
      },
    },
    outlineDark: {
      ...BUTTON_COMMON,
      backgroundColor: 'transparent',
      borderColor: 'background',
      color: 'textDark',
      ':hover': {
        color: 'textDarkInteraction',
        borderColor: 'backgroundInteraction',
        backgroundColor: 'backgroundInteraction',
      },
    },
    padded: {
      background: 0,
      display: 'block',
      margin: '-8px',
      padding: '8px',
      cursor: 'pointer',
    },
    unstyled: {
      background: 0,
      padding: 0,
    },
  },
  forms: {
    input: DEFAULT_FORM_FIELD,
    error: {
      ...DEFAULT_FORM_FIELD,
      borderColor: 'danger',
      ':focus': {
        borderColor: 'danger',
      },
    },
  },
  accessibility: {
    srOnly: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      whiteSpace: 'nowrap',
      borderWidth: 0,
    },
  },
  shadows: {
    subtle: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  transitions: {
    default: '250ms ease-in-out',
  },
  zIndices: {
    header: 7500,
  },
}
