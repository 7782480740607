import smoothscroll from 'smoothscroll-polyfill'
import wrapRootElement from './src/WrapRootElement'
import wrapPageElement from './src/WrapPageElement'

const scrollToElementByHash = hash => {
  if (!hash) return
  const el = document.querySelector(hash)
  if (!el) return
  el.scrollIntoView()
  el.focus()
}

const onClientEntry = () => {
  smoothscroll.polyfill()
}

const onRouteUpdate = ({ location }) => {
  scrollToElementByHash(location.hash)
}

export {
  onClientEntry,
  onRouteUpdate,
  wrapPageElement,
  wrapRootElement,
}
