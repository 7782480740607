import { node } from 'prop-types'

const DISPLAY_NAME = 'WrapRootElement'

const PROP_TYPES = {
  element: node.isRequired,
}

const Component = ({ element }) => element

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
